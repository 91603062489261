import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';

const fieldEncoder = `
	encoder {
		id
		name
		firstName
		fullName
	}
`;

const fieldUser = `
	user {
		id
		name
		firstName
	}
`;

const columnTimesheet = `
	id
	strDate
	endDate
	week
	totMin
	validated
	comment
	salary
	totMinOvertime
	totMinNight
	totMinTooEarly
	kgCoTwo
	totMinOvertime1
	totMinOvertime2
	transportTimePaid
	hasNotSubmittedDay
	weeklyTotalOvertime
	weeklyTotalOvertimeStr
	weeklyTotMinOvt
	weeklyTotMinOvt1
	weeklyTotMinOvt2
	weeklySalaryOvt
	weeklySalaryOvt1
	weeklySalaryOvt2
	weeklyCarAllowance
	weeklyComputerAllowance
	weeklyProductionFeeAllowance
	weeklyPhoneAllowance
	weeklyBoxKitAllowance
	dailyTotAllowances
	weeklyTotAllowances
	weeklyTotPerDiem
	hours
	minutes
	hoursOvertime
	minutesOvertime
	hoursOvertime1
	hoursOvertime2
	minutesOvertime1
	minutesOvertime2
	hoursTransportTimePaid
	minutesTransportTimePaid
	hoursNight
	minutesNight
	hoursTooEarly
	minutesTooEarly
	department
	departmentName
	function
	functionName
	weeklyTotMinOvtStr
	weeklyTotMinOvt1Str
	weeklyTotMinOvt2Str
	weeklyLunchPerDiem
	weeklyHotelPerDiem
	weeklyDinnerPerDiem
	weeklyAbroadPerDiem
	encoderId
	jsError
	jsLog
`;

const res_field = `
	id
	createdAt
	updatedAt
	userProjectId
	date
	remark
	strTime
	endTime
	actualEndTime
	lunchStrTime
	lunchEndTime
	lunch
	hotel
	useDinner
	useAbroad
	teleworking
	totMin
	validated
	timesheetId
	laValidatedId
	laValidatedWhen
	lbValidatedId
	lbValidatedWhen
	comment
	stageDayUsed
	dayNumber
	salaryBase
	salary
	salaryOvertime
	salaryHourNight
	salaryHourTooEarly
	totMinOvertime
	leftHomeAt
	returnedHomeAt
	totMinNight
	totMinTooEarly
	dayType
	dayRate
	kgCoTwo
	totMinOvertime1
	totMinOvertime2
	salaryOvertime1
	salaryOvertime2
	transportTimePaid
	salaryTransport
	carAllowance
	productionFeeAllowance
	computerAllowance
	phoneAllowance
	boxKitAllowance
	totAllowances
	totPerDiem
	lunchPerDiem
	hotelPerDiem
	dinnerPerDiem
	abroadPerDiem
	notSpecifiedLunch
	useMileageToSet
	mileageToSetAllowance
	transportFromHomeToWork
	hours
	minutes
	hoursOvertime
	minutesOvertime
	hoursOvertime1
	minutesOvertime1
	hoursOvertime2
	minutesOvertime2
	hoursTransportTimePaid
	minutesTransportTimePaid
	hoursNight
	minutesNight
	hoursTooEarly
	minutesTooEarly
	strEndNotSpecified
	isInContract
	dayIsNotWorkHoliday
	jsError
	jsLog
	location {
	  id
	}
	stageDay {
		id
		createdAt
		updatedAt
		projectId
		date
		strTime
		endTime
		actualEndTime
		lunchStrTime
		lunchEndTime
		totMin
		comment
		useHours
		useMileageToSet
		kmToSet
		kmToSetSalary
		kmToSetDescription
		hoursDefined
		kmToSetDefined
		hours
		minutes
		is2NdShooting
		strTime2
		endTime2
		actualEndTime2
		lunchStrTime2
		lunchEndTime2
		is3RdShooting
		strTime3
		endTime3
		actualEndTime3
		lunchStrTime3
		lunchEndTime3
		totMin2
		totMin3
		hours2
		minutes2
		hours3
		minutes3
	}
	${fieldUser}
	encoderId
    ${fieldEncoder}
	contract {
		useDinnerPerDiem
		useAbroadPerDiem
		useLunchPerDiem
		useHotelPerDiem
		useCarAllowance
		useProductionFeeAllowance
		useComputerAllowance
		usePhoneAllowance
		useBoxKitAllowance
		hotelPerDiem
		dinnerPerDiem
		carAllowanceRate
		productionFeeAllowanceRate
		computerAllowanceRate
		phoneAllowanceRate
		boxKitAllowanceRate
		abroadPerDiem
		lunchPerDiem
	}
	timesheet {
		${columnTimesheet}
		${fieldEncoder}
	}
`;

const stage_day_cols = `
	id
	createdAt
	updatedAt
	projectId
	date
	strTime
	endTime
	actualEndTime
	lunchStrTime
	lunchEndTime
	totMin
	comment
	useHours
	useMileageToSet
	kmToSet
	kmToSetSalary
	kmToSetDescription
	hoursDefined
	kmToSetDefined
	hours
	minutes
	is2NdShooting
	strTime2
	endTime2
	actualEndTime2
	lunchStrTime2
	lunchEndTime2
	is3RdShooting
	strTime3
	endTime3
	actualEndTime3
	lunchStrTime3
	lunchEndTime3
	totMin2
	totMin3
	hours2
	minutes2
	hours3
	minutes3
`;

const timesheetField = `
	${columnTimesheet}
	${fieldUser}
	${fieldEncoder}
`;
/**
 * @todo
 * Back Julis
 *
 * beforeCallTime
 * dailyBeforeCallTime
 * weeklyBeforeCallTime
 */

/**
 * Validate timesheet
 * @param {Int} id
 */
export const validateTimesheet = async (id, comment = '') => {
	const mutation = gql`
		mutation ($id: ID!, $Comment: String) {
			ValidateTimesheet(TimesheetId: $id, Comment: $Comment) {
				id
			}
		}
	`;
	const {
		data: { ValidateTimesheet }
	} = await apollo.mutate({
		mutation,
		variables: {
			id,
			comment
		},
		fetchPolicy: 'no-cache'
	});

	return ValidateTimesheet;
};

export const getStageDays = async (startDate, endDate) => {
	try {
		const QUERY_GET_STAGE_DAYS = gql`
			query ($StartDate: String, $EndDate: String) {
				GetStageDays(StartDate: $StartDate, EndDate: $EndDate) {
					${res_field}
				}
			}
		`;

		const {
			data: { GetStageDays }
		} = await apollo.query({
			query: QUERY_GET_STAGE_DAYS,
			variables: {
				StartDate: startDate,
				EndDate: endDate
			},
			fetchPolicy: 'no-cache'
		});

		return GetStageDays;
	} catch (e) {
		console.log(e);
	}
};

export const addUpdStageDay = async (stageDayId = 0, updStageDay = {}) => {
	try {
		const MUTATION_ADD_UPD_STAGE_DAY = gql`
			mutation ($stageDayId: ID!, $updStageDay: StageDayInput!) {
				AddUpdStageDay(StageDayId: $stageDayId, UpdStageDay: $updStageDay) {
					${res_field}
				}
			}
		`;

		const {
			data: { AddUpdStageDay }
		} = await apollo.mutate({
			mutation: MUTATION_ADD_UPD_STAGE_DAY,
			variables: {
				stageDayId: parseInt(stageDayId, 10),
				updStageDay
			}
		});

		return AddUpdStageDay;
	} catch (e) {
		console.log(e);
	}
};

export const delStageDay = async (stageDayId) => {
	try {
		const MUTATION_DEL_STAGE_DAY = gql`
			mutation ($stageDayId: ID!) {
				DelStageDay(TsDayId: $stageDayId)
			}
		`;

		const {
			data: { DelStageDay }
		} = await apollo.mutate({
			mutation: MUTATION_DEL_STAGE_DAY,
			variables: {
				stageDayId: parseInt(stageDayId, 10)
			}
		});

		return DelStageDay;
	} catch (e) {
		console.log(e);
	}
};

export const getTsDays = async (StartDate) => {
	try {
		const QUERY_GET_TS_DAYS = gql`
			query($StartDate: String) {
				myTsDays(StartDate: $StartDate) {
					${res_field}
				}
			}
		`;

		const {
			data: { myTsDays }
		} = await apollo.query({
			query: QUERY_GET_TS_DAYS,
			variables: {
				StartDate
			},
			fetchPolicy: 'no-cache'
		});

		return myTsDays;
	} catch (e) {
		console.log(e);
	}
};

export const saveTsDaysForCrew = async (
	TsDayEntryForCrewInput,
	StrTime,
	EndTime,
	LunchStrTime,
	LunchEndTime,
	Lunch,
	Hotel,
	UseDinner,
	UseAbroad,
	DayType,
	LeftHomeAt,
	ReturnedHomeAt,
	NotSpecifiedLunch,
	UseMileageToSet,
	TransportFromHomeToWork,
	StrEndNotSpecified,
	LocationId,
	Remark
) => {
	try {
		const MUTATION_SAVE_TS_DAY_FOR_CREW = gql`
			mutation (
				$TsDayEntryForCrewInput: [TsDayEntryForCrewInput]!
				$StrTime: String
				$EndTime: String
				$LunchStrTime: String
				$LunchEndTime: String
				$Lunch: Boolean
				$Hotel: Boolean
				$UseDinner: Boolean
				$UseAbroad: Boolean
				$DayType: Int
				$LeftHomeAt: String
				$ReturnedHomeAt: String
				$NotSpecifiedLunch: Boolean
				$UseMileageToSet: Boolean
				$TransportFromHomeToWork: Boolean
				$StrEndNotSpecified: Boolean
				$LocationId: ID
				$Remark: String
			) {
				AddUpdTsDayForUsers(
					TsDayEntryForCrewInput: $TsDayEntryForCrewInput
					StrTime: $StrTime
					EndTime: $EndTime
					LunchStrTime: $LunchStrTime
					LunchEndTime: $LunchEndTime
					Lunch: $Lunch
					Hotel: $Hotel
					UseDinner: $UseDinner
					UseAbroad: $UseAbroad
					DayType: $DayType
					LeftHomeAt: $LeftHomeAt
					ReturnedHomeAt: $ReturnedHomeAt
					NotSpecifiedLunch: $NotSpecifiedLunch
					UseMileageToSet: $UseMileageToSet
					TransportFromHomeToWork: $TransportFromHomeToWork
					StrEndNotSpecified: $StrEndNotSpecified
					LocationId: $LocationId
					Remark: $Remark
				) {
					id
					createdAt
					updatedAt
					userId
					date
					user {
						id
						name
						firstName
					}
				}
			}
		`;
		const {
			data: { AddUpdTsDayForUsers }
		} = await apollo.mutate({
			mutation: MUTATION_SAVE_TS_DAY_FOR_CREW,
			variables: {
				TsDayEntryForCrewInput,
				StrTime,
				EndTime,
				LunchStrTime,
				LunchEndTime,
				Lunch,
				Hotel,
				UseDinner,
				UseAbroad,
				DayType,
				LeftHomeAt,
				ReturnedHomeAt,
				NotSpecifiedLunch,
				UseMileageToSet,
				TransportFromHomeToWork,
				StrEndNotSpecified,
				LocationId,
				Remark
			}
		});
		return AddUpdTsDayForUsers;
	} catch (e) {
		console.log(e);
	}
};

export const saveTsDay = async (
	Date,
	StrTime,
	EndTime,
	LunchStrTime,
	LunchEndTime,
	Lunch,
	Hotel,
	UseDinner,
	UseAbroad,
	DayType,
	LeftHomeAt,
	ReturnedHomeAt,
	NotSpecifiedLunch,
	UseMileageToSet,
	TransportFromHomeToWork,
	UserId,
	StrEndNotSpecified,
	LocationId,
	UseStageDay,
	Comment,
	Remark
) => {
	try {
		const MUTATION_SAVE_TS_DAY = gql`
			mutation (
				$Date: String!
				$StrTime: String!
				$EndTime: String
				$LunchStrTime: String
				$LunchEndTime: String
				$Lunch: Boolean
				$Hotel: Boolean
				$UseDinner: Boolean
				$UseAbroad: Boolean
				$DayType: Int
				$LeftHomeAt: String
				$ReturnedHomeAt: String
				$NotSpecifiedLunch: Boolean
				$UseMileageToSet: Boolean
        		$TransportFromHomeToWork: Boolean
				$UserId: ID
				$StrEndNotSpecified: Boolean
				$LocationId: ID
				$UseStageDay: Boolean
				$Comment: String
				$Remark: String
			) {
				AddUpdTsDay(
					Date: $Date
					StrTime: $StrTime
					EndTime: $EndTime
					LunchStrTime: $LunchStrTime
					LunchEndTime: $LunchEndTime
					Lunch: $Lunch
					Hotel: $Hotel
					UseDinner: $UseDinner
					UseAbroad: $UseAbroad
					DayType: $DayType
					LeftHomeAt: $LeftHomeAt
					ReturnedHomeAt: $ReturnedHomeAt
					NotSpecifiedLunch: $NotSpecifiedLunch
					UseMileageToSet: $UseMileageToSet
					TransportFromHomeToWork: $TransportFromHomeToWork
					UserId: $UserId
					StrEndNotSpecified: $StrEndNotSpecified
					LocationId: $LocationId
					UseStageDay: $UseStageDay
					Comment: $Comment
					Remark: $Remark
				) {
					${res_field}
				}
			}
		`;

		const {
			data: { AddUpdTsDay }
		} = await apollo.mutate({
			mutation: MUTATION_SAVE_TS_DAY,
			variables: {
				Date,
				StrTime,
				EndTime,
				LunchStrTime,
				LunchEndTime,
				Lunch,
				Hotel,
				UseDinner,
				UseAbroad,
				DayType,
				LeftHomeAt,
				ReturnedHomeAt,
				NotSpecifiedLunch,
				UseMileageToSet,
				TransportFromHomeToWork,
				UserId,
				StrEndNotSpecified,
				LocationId,
				UseStageDay,
				Comment,
				Remark
			}
		});

		return AddUpdTsDay;
	} catch (e) {
		console.log(e);
	}
};

export const submitSelectedTimesheet = async (tsDayId) => {
	try {
		const MUTATION_SUBMIT_SELECTED_TIMESHEET = gql`
			mutation SUBMIT_SELECTED_TIMESHEET($tsDayId: [ID]!) {
				SubmitSelectedTimesheet(TsDayId: $tsDayId)
			}
		`;

		const {
			data: { SubmitSelectedTimesheet }
		} = await apollo.mutate({
			mutation: MUTATION_SUBMIT_SELECTED_TIMESHEET,
			variables: {
				tsDayId
			}
		});

		return SubmitSelectedTimesheet;
	} catch (e) {
		console.log(e);
	}
};

export const delTimesheet = async (tsDayId) => {
	try {
		const MUTATION_DEL_TIMESHEET = gql`
			mutation ($TsDayId: ID!) {
				DelTsDay(TsDayId: $TsDayId)
			}
		`;

		const {
			data: { DelTsDay }
		} = await apollo.mutate({
			mutation: MUTATION_DEL_TIMESHEET,
			variables: {
				TsDayId: parseInt(tsDayId, 10)
			}
		});

		return DelTsDay;
	} catch (e) {
		console.log(e);
	}
};

export const getTimesheetDetail = async (TimesheetId) => {
	try {
		const QUERY_GET_TIMESHEET_DETAIL = gql`
			query ($TimesheetId: Int!) {
				TimesheetDetails(TimesheetId: $TimesheetId) {
					${res_field}
				}
			}
		`;

		const {
			data: { TimesheetDetails }
		} = await apollo.query({
			query: QUERY_GET_TIMESHEET_DETAIL,
			variables: {
				TimesheetId: parseInt(TimesheetId)
			},
			fetchPolicy: 'no-cache'
		});

		return TimesheetDetails;
	} catch (e) {
		console.log(e);
	}
};

export const rejectTsDay = async (tsDayId, comment) => {
	try {
		const MUTATION_REJECT_TS_DAY = gql`
			mutation ($TsDayId: ID!, $Comment: String) {
				RejectTsDay(TsDayId: $TsDayId, Comment: $Comment) {
					id
				}
			}
		`;

		const {
			data: { RejectTsDay }
		} = await apollo.mutate({
			mutation: MUTATION_REJECT_TS_DAY,
			variables: {
				TsDayId: parseInt(tsDayId),
				Comment: comment
			}
		});

		return RejectTsDay;
	} catch (e) {
		console.log(e);
	}
};

export const getStageDay = async (date) => {
	const QUERY_GET_STAGE_DAY = gql`
		query ($date: String!) {
			GetStageDay(Date: $date) {
				${stage_day_cols}
			}
		}
	`;

	const {
		data: { GetStageDay }
	} = await apollo.query({
		query: QUERY_GET_STAGE_DAY,
		variables: {
			date
		},
		fetchPolicy: 'no-cache'
	});

	return GetStageDay;
};

export const addTsDaysUsersForPlanning = async (TsDayEntryForCrewInput = []) => {
	try {
		const mutation = gql`
			mutation ($TsDayEntryForCrewInput: [TsDayEntryForCrewInput]!) {
				AddUpdTsDayForUsers(TsDayEntryForCrewInput: $TsDayEntryForCrewInput) {
					id
					createdAt
					updatedAt
					userId
					date
					user {
						id
						name
						firstName
					}
				}
			}
		`;

		const {
			data: { AddUpdTsDayForUsers }
		} = await apollo.mutate({
			mutation,
			variables: {
				TsDayEntryForCrewInput
			}
		});

		return AddUpdTsDayForUsers;
	} catch (e) {
		console.log({ ADD_TS_DAYS_USERS_PLANNING_ERROR: e });
	}
};

export const getMyTimesheets = async () => {
	const query = gql`
		query {
			myTimesheets {
				${timesheetField}
			}
		}
	`;

	const {
		data: { myTimesheets }
	} = await apollo.query({
		query,
		fetchPolicy: 'network-only'
	});

	return myTimesheets;
};

export const getTimesheetsToValidate = async () => {
	const query = gql`
		query {
			GetTimesheetsToValidate {
				${timesheetField}
			}
		}
	`;

	const {
		data: { GetTimesheetsToValidate }
	} = await apollo.query({
		query,
		fetchPolicy: 'network-only'
	});

	return GetTimesheetsToValidate;
};

export const getTimesheetdetailWithfilter = async (TimesheetId, UnLoadNotSubmittedDay) => {
	const query = gql`
		query ($TimesheetId: Int!, $UnLoadNotSubmittedDay: Boolean) {
			TimesheetDetails(TimesheetId: $TimesheetId, UnLoadNotSubmittedDay: $UnLoadNotSubmittedDay) {
				id
				userProjectId
				date
				remark
				strTime
				endTime
				lunchStrTime
				lunchEndTime
				lunch
				hotel
				useDinner
				useAbroad
				teleworking
				totMin
				validated
				timesheetId
				comment
				stageDayUsed
				dayNumber
				salaryBase
				totPerDiem
				salary
				salaryOvertime
				salaryOvertime1
				salaryOvertime2
				salaryHourNight
				salaryHourTooEarly
				totMinOvertime
				leftHomeAt
				returnedHomeAt
				totMinNight
				totMinTooEarly
				dayType
				dayRate
				kgCoTwo
				totMinOvertime1
				totMinOvertime2
				transportTimePaid
				salaryTransport
				carAllowance
				productionFeeAllowance
				computerAllowance
				phoneAllowance
				boxKitAllowance
				totAllowances
				hours
				minutes
				hoursOvertime
				minutesOvertime1
				minutesOvertime
				minutesOvertime2
				hoursOvertime1
				hoursOvertime2
				hoursTransportTimePaid
				minutesTransportTimePaid
				hoursNight
				minutesNight
				minutesTooEarly
				hoursTooEarly
				mileageToSetAllowance
				jsError
				jsLog
				timesheet {
					${timesheetField}
				}
				${fieldUser}
				encoderId
				${fieldEncoder}
				contract {
					useDinnerPerDiem
					useAbroadPerDiem
					useLunchPerDiem
					useHotelPerDiem
					useCarAllowance
					useProductionFeeAllowance
					useComputerAllowance
					usePhoneAllowance
					useBoxKitAllowance
					carAllowanceRate
					productionFeeAllowanceRate
					computerAllowanceRate
					phoneAllowanceRate
					boxKitAllowanceRate
					hotelPerDiem
					lunchPerDiem
					dinnerPerDiem
					abroadPerDiem
				}
			}
		}
	`;

	const {
		data: { TimesheetDetails }
	} = await apollo.query({
		query,
		variables: {
			TimesheetId,
			UnLoadNotSubmittedDay
		},
		fetchPolicy: 'network-only'
	});

	return TimesheetDetails;
};

export const cancelRejectTsDay = async (TsDayId) => {
	const mutation = gql`
		mutation ($TsDayId: ID!) {
			UndoRejectTsDay(TsDayId: $TsDayId) {
				id
			}
		}
	`;

	const {
		data: { UndoRejectTsDay }
	} = await apollo.mutate({
		mutation,
		variables: {
			TsDayId
		}
	});

	return UndoRejectTsDay;
};

export const rejectTimesheet = async (TimesheetId, Comment) => {
	const mutation = gql`
		mutation ($TimesheetId: ID!, $Comment: String) {
			RejectTimesheet(TimesheetId: $TimesheetId, Comment: $Comment) {
				id
			}
		}
	`;

	const {
		data: { RejectTimesheet }
	} = await apollo.mutate({
		mutation,
		variables: {
			TimesheetId,
			Comment
		}
	});

	return RejectTimesheet;
};
